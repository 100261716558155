import type { ParsedUrlQuery } from 'querystring';
import { normalizedApiError } from './utils/normalized-api-error';
import { normalizedFetch } from './utils/normalized-fetch';
import { PROXY_ROUTES } from './ui-proxy-routes';

export const getUserFavoriteListingIds = async ({ query = {}, userId }: { query?: ParsedUrlQuery; userId: string }) => {
    try {
        return await normalizedFetch({
            endpoint: PROXY_ROUTES.fetchUserFavoriteListingIds.build({
                queryParams: query,
                userId
            }),
            options: {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        });
    } catch (err) {
        return normalizedApiError(err);
    }
};

export const getUserFavoriteListings = async ({ query = {}, userId }: { query?: ParsedUrlQuery; userId: string }) => {
    try {
        return await normalizedFetch({
            endpoint: PROXY_ROUTES.fetchUserFavoriteListings.build({
                queryParams: query,
                userId
            }),
            options: {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        });
    } catch (err) {
        return normalizedApiError(err);
    }
};
