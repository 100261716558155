import React, { MouseEvent, useCallback } from 'react';
import { type TFunction, useTranslation } from 'next-i18next';
import styled from '@emotion/styled';
import Checkbox from '@mui/material/Checkbox';
import CheckIcon from '@mui/icons-material/Check';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterListIcon from '@mui/icons-material/FilterList';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import Typography from '@mui/material/Typography';
import type { SearchFilter } from '@bladebinge/types';
import { noop } from '../../utils/noop';
import { ORDERED_KNIFE_FILTER_FILTER_TYPES } from '../../utils/constants';
import { groupFiltersByFilterProperty } from '../../utils/search-filters/group-filters-by-filter-property';
import { useSearchFilterContext } from '../../context/search-filter/search-filter-context';

const StyledCheckboxWrap = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center'
}));

interface RenderTree {
    expanded?: boolean;
    categoryCount?: number;
    id?: number;
    filterTypeSlug?: string;
    name: string;
    children?: RenderTree[];
    isSelected?: boolean;
    selectedTally?: number;
    slug?: string;
}

const checkBoxClicked = ({
    event,
    filterSelections,
    id,
    setFilterSelections
}: {
    event: React.ChangeEvent | MouseEvent;
    filterSelections: number[];
    id: number;
    setFilterSelections: (ids: number[]) => void;
}) => {
    event.stopPropagation();
    const selectionSet = new Set(filterSelections);

    if (selectionSet.has(id)) {
        selectionSet.delete(id);
    } else {
        selectionSet.add(id);
    }

    setFilterSelections(Array.from(selectionSet));
};

const FILTER_CLASS_CATEGORY_ORDERS: { [key: string]: string[] } = {
    knife: ORDERED_KNIFE_FILTER_FILTER_TYPES,
    universal: ['listing_category', 'condition', 'price_range', 'country_of_origin']
};

const visualFilterSort = ({ sortOrderIndex: orderA }: SearchFilter, { sortOrderIndex: orderB }: SearchFilter) =>
    orderA - orderB;

const filtersToTreeStructure = ({
    activeSearch,
    filterClass,
    filters = [],
    t,
    filterSelections
}: {
    activeSearch: string;
    filterClass: string;
    filters: SearchFilter[];
    t: TFunction;
    filterSelections: number[];
}) => {
    const groupedFilters = groupFiltersByFilterProperty({ filters, groupOnProperty: 'filterTypeSlug' });
    const categoryKeysOrder = FILTER_CLASS_CATEGORY_ORDERS[filterClass] ?? Object.keys(groupedFilters);

    return categoryKeysOrder.map((categorySlug) => {
        const filtersInCategory: SearchFilter[] = groupedFilters[categorySlug] || [];
        const categoryCount = filtersInCategory.length;
        const children = filtersInCategory
            .sort(visualFilterSort)
            .map(({ id: filterId, slug }) => ({
                id: filterId,
                name: t(`common:filters.${categorySlug}.${slug}`),
                isSelected: filterSelections.includes(filterId),
                slug
            }))
            .filter(({ isSelected, name }) => {
                if (!activeSearch) {
                    return true;
                }

                if (isSelected) {
                    return true;
                }

                const isSearchHit = name.toLowerCase().includes(activeSearch);

                return isSearchHit;
            });

        const badgeCount = children.filter(({ id }) => filterSelections.includes(id)).length;
        const expanded = Boolean(children.length > 0 && activeSearch);

        return {
            expanded,
            filterTypeSlug: categorySlug,
            name: t(`common:filters.filter_type.${categorySlug}`),
            children,
            ...(categoryCount ? { categoryCount } : {}),
            ...(badgeCount ? { selectedTally: badgeCount } : {})
        };
    });
};

export const FilterTree = () => {
    const { t } = useTranslation();
    const { activeFilterDrawerSearch, orderedFilterCategories, selectedDrawerFilterIds, setSelectedDrawerFilterIds } =
        useSearchFilterContext();

    const renderSubcategoryTreeRecursively = useCallback(
        (nodes: RenderTree[]) =>
            nodes.map((node) => {
                const hasId = Boolean(node.id);
                const itemId = hasId ? node.id : node?.filterTypeSlug;
                const isCheckedFilter = node.isSelected;
                const itemChangeHandler = (event: React.ChangeEvent | MouseEvent) =>
                    checkBoxClicked({
                        event,
                        id: itemId as number,
                        filterSelections: selectedDrawerFilterIds,
                        setFilterSelections: setSelectedDrawerFilterIds
                    });

                const hasChildren = Array.isArray(node.children);
                const childCount = (node?.children ?? []).length;
                const onItemClick = hasId ? itemChangeHandler : noop;

                const label = hasId ? (
                    <StyledCheckboxWrap>
                        <Checkbox
                            sx={{ padding: '0 .25rem 0 0' }}
                            id={`checkbox-${itemId}`}
                            checked={isCheckedFilter}
                            onChange={itemChangeHandler}
                            onClick={(e) => e.stopPropagation()}
                        />
                        <Typography variant="caption">{node.name}</Typography>
                    </StyledCheckboxWrap>
                ) : (
                    <Typography variant="body1" sx={{ fontStyle: 'italic' }}>
                        {node.name}
                        {node.categoryCount && (
                            <Typography
                                variant="caption"
                                sx={{
                                    position: 'relative',
                                    opacity: 0.75,
                                    fontSize: '0.85em',
                                    ml: 2
                                }}
                            >
                                {node.selectedTally ? (
                                    <>
                                        <CheckIcon
                                            sx={{
                                                position: 'relative',
                                                top: '2px',
                                                fontSize: '0.9em',
                                                mx: 0.5,
                                                color: 'info.main'
                                            }}
                                        />
                                        {node.selectedTally}
                                    </>
                                ) : null}
                                {childCount === 0 || childCount === node.categoryCount ? null : (
                                    <>
                                        <FilterListIcon
                                            sx={{
                                                position: 'relative',
                                                top: '2px',
                                                fontSize: '0.9em',
                                                mx: 0.5,
                                                color: 'warning.main'
                                            }}
                                        />
                                        {childCount}
                                    </>
                                )}
                            </Typography>
                        )}
                    </Typography>
                );

                return (
                    <TreeItem
                        sx={{
                            opacity: hasChildren && childCount === 0 ? 0.4 : 1,
                            marginLeft: 0,
                            padding: 0,
                            textIndent: 0
                        }}
                        key={itemId}
                        itemId={typeof itemId === 'string' ? itemId : (itemId as number).toString()}
                        label={label}
                        onClick={onItemClick}
                        {...(node?.slug ? { 'data-slug': node.slug } : { 'data-categoryslug': node?.filterTypeSlug })}
                    >
                        {Array.isArray(node.children) ? renderSubcategoryTreeRecursively(node.children) : null}
                    </TreeItem>
                );
            }),
        [selectedDrawerFilterIds, setSelectedDrawerFilterIds]
    );

    const renderTreeItems = useCallback(
        () =>
            orderedFilterCategories.map((filtersForClass) => {
                const filterClass = filtersForClass?.[0]?.filterClass;

                const dataNodes: RenderTree[] = filtersToTreeStructure({
                    activeSearch: activeFilterDrawerSearch,
                    filterClass,
                    filters: filtersForClass,
                    t,
                    filterSelections: selectedDrawerFilterIds
                });

                return (
                    <div key={`tree_${filterClass}`}>
                        <Typography variant="h6" sx={{ mt: 1.5 }}>
                            {t(`common:filters.filter_class.${filterClass}`)}
                        </Typography>
                        <SimpleTreeView
                            sx={{
                                padding: 0,
                                textIndent: 0,
                                marginLeft: 0,
                                flexGrow: 1,
                                maxWidth: 400
                            }}
                            defaultExpandedItems={['root']}
                            slots={{
                                expandIcon: ExpandMoreIcon,
                                collapseIcon: ChevronRightIcon
                            }}
                        >
                            {renderSubcategoryTreeRecursively(dataNodes)}
                        </SimpleTreeView>
                    </div>
                );
            }),
        [
            activeFilterDrawerSearch,
            selectedDrawerFilterIds,
            orderedFilterCategories,
            renderSubcategoryTreeRecursively,
            t
        ]
    );

    return <>{renderTreeItems()}</>;
};
