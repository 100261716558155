import React from 'react';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import { useMe } from '../../context/me/me-context';
import { UserMenu } from './UserMenu';

const StyledLoginLink = styled(Link)(({ theme }) => ({
    textDecoration: 'none'
}));

export const AuthNav = () => {
    const { t } = useTranslation();
    const { asPath } = useRouter();
    const { id } = useMe();

    const showLogin = !asPath.includes('login');
    const isSignedIn = Boolean(id);

    const loginUrl = `/login${asPath ? `?redirect=${encodeURIComponent(asPath)}` : ''}`;

    return isSignedIn ? (
        <nav>
            <UserMenu />
        </nav>
    ) : (
        <nav>
            <ul className="nav-links d-flex justify-content-between align-items-center">
                {showLogin && (
                    <li key="login_button">
                        <StyledLoginLink href={loginUrl}>
                            <Button id="nav_login_btn" variant="contained" color="primary" size="small">
                                {t('common:auth.login')}
                            </Button>
                        </StyledLoginLink>
                    </li>
                )}
            </ul>
        </nav>
    );
};
