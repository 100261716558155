import React, { useMemo, useState } from 'react';
import { default as NextImage } from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import styled from '@emotion/styled';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import OfflineShareIcon from '@mui/icons-material/OfflineShare';
import SellIcon from '@mui/icons-material/Sell';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LogoutIcon from '@mui/icons-material/Logout';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MessageIcon from '@mui/icons-material/Message';
import { getCdnUrl } from '@bladebinge/web-service-common/src/utils/get-cdn-url';
import { UserProfileDisplayGraph } from '@bladebinge/types';
import { getActiveAvatarThumbnailFromLocalStorage } from '@bladebinge/web-service-common/src/utils/local-storage/local-storage-util';
import Badge from '@mui/material/Badge';
import Favorite from '@mui/icons-material/Favorite';
import { useMe } from '../../context/me/me-context';
import { useAccountGraphQuery } from '../../hooks/react-query/use-account-graph-query';
import { useLoggedInUserActiveProfile } from '../../hooks/react-query/logged-in-user-hooks/use-logged-in-user-active-profile';
import { useHasMounted } from '../../hooks/use-has-mounted';
import { useUserInterfaceNotificationsContext } from '../../context/user-interface-notifications/user-interface-notifications-context';
import { useUserFavoriteListingIds } from '../../hooks/react-query/logged-in-user-hooks/use-user-favorite-listing-ids';

const StyledMenuAnchor = styled(Link)(({ theme }) => ({
    color: theme.palette.secondary.main,
    display: 'block',
    textDecoration: 'none',
    width: '100%',
    minWidth: '150px',
    '& > span': {
        display: 'inline-block',
        verticalAlign: 'top'
    }
}));

export const UserMenu = () => {
    const { t } = useTranslation();
    const { asPath } = useRouter();
    const hasMounted = useHasMounted();

    const { countsData: notificationCountsData, feedbackTotalCount } = useUserInterfaceNotificationsContext();

    const { id: currentUserId, logoutUser } = useMe();

    // Tanstack/react-query
    const { data: accountGraph } = useAccountGraphQuery(currentUserId);
    const { data: activeProfile } = useLoggedInUserActiveProfile({
        userId: currentUserId
    });
    const { data: userFavoriteIdsData = [] } = useUserFavoriteListingIds({
        userId: currentUserId
    });

    const userFavoritesTotalCount = useMemo(
        () => (userFavoriteIdsData && userFavoriteIdsData?.length ? userFavoriteIdsData.length : 0),
        [userFavoriteIdsData]
    );

    const { avatar, username } = activeProfile ?? ({} as UserProfileDisplayGraph);
    const hasReferrer = Boolean(accountGraph?.referrer) && accountGraph.referrer.isApproved;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [copyNoteMessage, setCopyNoteMessage] = useState<string>('');
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = async () => {
        await logoutUser();
    };

    const copyReferralUrl = () => {
        const code = accountGraph?.referrer?.referralCode?.code;

        if (!code) {
            return;
        }

        try {
            const currentUrl = new URL(window.location.href);

            // we allow referral links
            // direct to product page
            // direct to user profile page
            // or to UI home page
            const isListingDirectLink = asPath.startsWith('/listings');
            const isProfileDirectLink = asPath.startsWith('/profiles');

            const copyableUrl =
                isListingDirectLink || isProfileDirectLink
                    ? new URL(window.location.href)
                    : new URL(process?.env?.ENV_UI_SERVER_URL ?? '');

            copyableUrl.searchParams.append('referrer', code);

            window.navigator.clipboard.writeText(copyableUrl.toString());
            setCopyNoteMessage(t('common:referrals.link_copied', { url: currentUrl }));
        } catch (err) {
            // do nothing, just try catch the winow operation
        }
    };

    const isMenuOpen = Boolean(anchorEl);
    const avatarThumbnail = avatar?.thumbnailUrl ?? getActiveAvatarThumbnailFromLocalStorage() ?? '';
    const showFavoritesItems = Number.isFinite(userFavoritesTotalCount) && userFavoritesTotalCount > 0;

    if (!hasMounted) {
        return null;
    }

    return (
        <>
            <Button
                aria-controls="customized-menu"
                aria-haspopup="true"
                id="nav_expand_user_menu_btn"
                variant="text"
                color="primary"
                onClick={handleClick}
                sx={{ px: 0 }}
            >
                <>
                    <Avatar
                        {...(avatarThumbnail ? { src: getCdnUrl(avatarThumbnail) } : {})}
                        sx={{
                            height: {
                                xs: '30px',
                                sm: '35px',
                                md: '45px'
                            },
                            width: {
                                xs: '30px',
                                sm: '35px',
                                md: '45px'
                            }
                        }}
                    >
                        {avatar?.thumbnailUrl ? (
                            <NextImage
                                alt={username ?? ''}
                                fill
                                priority
                                sizes="(max-width: 600px) 30px, (max-width: 900px) 35px, 45px"
                                src={getCdnUrl(avatar.thumbnailUrl)}
                            />
                        ) : (
                            (username ?? '').charAt(0)
                        )}
                    </Avatar>
                    {isMenuOpen ? (
                        <ExpandLessIcon sx={{ color: 'common.white' }} />
                    ) : (
                        <ExpandMoreIcon sx={{ color: 'common.white' }} />
                    )}
                </>
            </Button>
            <Menu
                anchorEl={anchorEl}
                elevation={3}
                id="customized-menu"
                keepMounted
                open={isMenuOpen}
                onClose={handleClose}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                <MenuItem>
                    <StyledMenuAnchor id="nav_user_account_btn" href={`/users/${currentUserId}/account`}>
                        <AccountCircleIcon color="primary" fontSize="small" sx={{ mt: 0.5, mr: 0.5 }} />
                        <Typography color="textSecondary" component="span">
                            {t('common:navigation.account')}
                        </Typography>
                    </StyledMenuAnchor>
                </MenuItem>
                <Divider sx={{ opacity: 0.1 }} />
                <MenuItem>
                    <StyledMenuAnchor id="nav_user_sell_btn" href={`/users/${currentUserId}/account?view=my_listings`}>
                        <SellIcon color="primary" fontSize="small" sx={{ mt: 0.5, mr: 0.5 }} />
                        <Typography color="textSecondary" component="span">
                            {t('common:navigation.sell')}
                        </Typography>
                    </StyledMenuAnchor>
                </MenuItem>
                <Divider sx={{ opacity: 0.1 }} />
                <MenuItem>
                    <StyledMenuAnchor id="nav_user_messages_btn" href={`/users/${currentUserId}/messaging`}>
                        <MessageIcon color="primary" fontSize="small" sx={{ mt: 0.5, mr: 0.5 }} />
                        <Typography color="textSecondary" component="span">
                            {t('common:navigation.messages')}
                        </Typography>
                    </StyledMenuAnchor>
                    {Number.isFinite(notificationCountsData.NEW_MESSAGE) && notificationCountsData.NEW_MESSAGE > 0 && (
                        <Badge
                            badgeContent={notificationCountsData.NEW_MESSAGE}
                            color="primary"
                            max={99}
                            overlap="rectangular"
                            variant="standard"
                            sx={{
                                float: 'right',
                                mr: 1
                            }}
                        />
                    )}
                </MenuItem>
                {showFavoritesItems && <Divider sx={{ opacity: 0.1 }} />}
                {showFavoritesItems && (
                    <MenuItem>
                        <StyledMenuAnchor id="nav_favorites_btn" href={`/users/${currentUserId}/favorites`}>
                            <Favorite color="primary" fontSize="small" sx={{ mt: 0.5, mr: 0.5 }} />
                            <Typography color="textSecondary" component="span">
                                {t('common:navigation.favorites_list')}
                            </Typography>
                        </StyledMenuAnchor>
                        <Badge
                            badgeContent={userFavoritesTotalCount}
                            color="primary"
                            max={50}
                            overlap="rectangular"
                            variant="standard"
                            sx={{
                                float: 'right',
                                mr: 1,
                                mb: 1
                            }}
                        />
                    </MenuItem>
                )}
                <Divider sx={{ opacity: 0.1 }} />
                <MenuItem>
                    <StyledMenuAnchor id="nav_feedback_btn" href={`/users/${currentUserId}/feedback`}>
                        <MessageIcon color="primary" fontSize="small" sx={{ mt: 0.5, mr: 0.5 }} />
                        <Typography color="textSecondary" component="span">
                            {t('common:navigation.feedback')}
                        </Typography>
                    </StyledMenuAnchor>
                    {Number.isFinite(feedbackTotalCount) && feedbackTotalCount > 0 && (
                        <Badge
                            badgeContent={feedbackTotalCount}
                            color="primary"
                            max={99}
                            overlap="rectangular"
                            variant="standard"
                            sx={{
                                float: 'right',
                                mr: 1
                            }}
                        />
                    )}
                </MenuItem>
                {username && <Divider sx={{ opacity: 0.1 }} />}
                {username && (
                    <MenuItem>
                        <StyledMenuAnchor id="nav_user_view_profile_btn" href={`/profiles/${username}`}>
                            <ContactPageIcon color="primary" fontSize="small" sx={{ mt: 0.5, mr: 0.5 }} />
                            <Typography color="textSecondary" component="span">
                                {t('common:navigation.view_profile')}
                            </Typography>
                        </StyledMenuAnchor>
                    </MenuItem>
                )}
                <Divider sx={{ opacity: 0.1 }} />
                <MenuItem>
                    <StyledMenuAnchor id="nav_user_preferences_btn" href={`/users/${currentUserId}/user_preferences`}>
                        <ManageAccountsIcon color="primary" fontSize="small" sx={{ mt: 0.5, mr: 0.5 }} />
                        <Typography color="textSecondary" component="span">
                            {t('common:navigation.preferences')}
                        </Typography>
                    </StyledMenuAnchor>
                </MenuItem>
                {hasReferrer && <Divider sx={{ opacity: 0.1 }} />}
                {hasReferrer && (
                    <MenuItem>
                        <StyledMenuAnchor
                            id="nav_referrer_account_btn"
                            href={`/users/${currentUserId}/referrer_account`}
                        >
                            <ManageAccountsIcon color="primary" fontSize="small" sx={{ mt: 0.5, mr: 0.5 }} />
                            <Typography color="textSecondary" component="span">
                                {t('common:navigation.referrer_account').toLowerCase()}
                            </Typography>
                        </StyledMenuAnchor>
                    </MenuItem>
                )}
                {hasReferrer && <Divider sx={{ opacity: 0.1 }} />}
                {hasReferrer && (
                    <MenuItem>
                        <OfflineShareIcon color="primary" fontSize="small" sx={{ mt: 0.5, mr: 0.5 }} />
                        <Button onClick={copyReferralUrl} variant="text" sx={{ pl: 0 }}>
                            <Typography color="textSecondary" component="span">
                                {t('common:referrals.copy_referral_link')}
                            </Typography>
                        </Button>
                    </MenuItem>
                )}
                <Divider />
                <MenuItem>
                    <Button
                        aria-controls="customized-menu"
                        aria-haspopup="true"
                        color="primary"
                        fullWidth
                        onClick={handleLogout}
                        size="small"
                        sx={{ pl: 0, justifyContent: 'flex-start' }}
                        variant="text"
                    >
                        <LogoutIcon color="primary" fontSize="small" sx={{ mt: 0.5, mr: 0.5 }} />
                        <Typography variant="body1" color="textSecondary" component="span">
                            {t('common:navigation.logout')}
                        </Typography>
                    </Button>
                </MenuItem>
            </Menu>
            <Snackbar
                open={Boolean(copyNoteMessage)}
                autoHideDuration={6000}
                onClose={() => setCopyNoteMessage('')}
                message={copyNoteMessage}
            />
        </>
    );
};
