import React, { useState } from 'react';
import { useTranslation } from 'next-i18next';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { SUPPORTED_LANGUAGE_KEYS } from '@bladebinge/web-service-common/src/constants/supported-language-keys';
import type { SupportedLanguage } from '@bladebinge/types';
import { useHasMounted } from '../../hooks/use-has-mounted';

export const SupportedLanguageSelector = ({
    isUserSettings = false,
    preselectedLanguage = 'en',
    onSelectLanguage
}: {
    readonly isUserSettings?: boolean;
    readonly preselectedLanguage: SupportedLanguage;
    readonly onSelectLanguage: (language: SupportedLanguage) => Promise<void> | void;
}) => {
    const { t } = useTranslation();
    const hasMounted = useHasMounted();

    const [locale, setLocale] = useState<string>(preselectedLanguage);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handleLanguageSelect = async (event: SelectChangeEvent<string>) => {
        const updatedLanguage = (event.target.value ?? 'en') as SupportedLanguage;
        setLocale(updatedLanguage);
        onSelectLanguage(updatedLanguage);
    };

    // prevent next SSR render differing from Client render and erroring
    if (!hasMounted) {
        return null;
    }

    return (
        <FormControl fullWidth size="small">
            <InputLabel
                id={`${isUserSettings ? 'settings_' : ''}language_selector_label`}
                size="small"
                variant="standard"
            >
                {t('common:languages.select_language')}
            </InputLabel>
            <Select
                fullWidth
                id={`${isUserSettings ? 'settings_' : ''}language_selector`}
                labelId={`${isUserSettings ? 'settings_' : ''}language_selector_label`}
                open={isOpen}
                onChange={handleLanguageSelect}
                onClose={() => setIsOpen(false)}
                onOpen={() => setIsOpen(true)}
                size="small"
                value={locale}
                variant="standard"
            >
                {SUPPORTED_LANGUAGE_KEYS.sort((a, b) =>
                    t(`common:languages.locales.${a}`).localeCompare(t(`common:languages.locales.${b}`))
                ).map((localeKey) => (
                    <MenuItem key={localeKey} value={localeKey}>
                        <Typography variant="body2">{t(`common:languages.locales.${localeKey}`)}</Typography>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
