import type { ParsedQuery } from 'query-string';
import type { ApiResponseOrError, UserProfileEntity } from '@bladebinge/types';
import { normalizedApiError } from './utils/normalized-api-error';
import { normalizedFetch } from './utils/normalized-fetch';
import { PROXY_ROUTES } from './ui-proxy-routes';

export const fetchAutocompleteByUsernameResults = async (
    {
        searchString = '',
        onlyActiveSellers = true
    }: {
        searchString: string;
        onlyActiveSellers: boolean;
    },
    signal: AbortSignal
) => {
    try {
        const autocompleteResponse = await normalizedFetch({
            endpoint: PROXY_ROUTES.fetchProfileAutocompleteResultsByUsername.build({
                searchString,
                onlyActiveSellers
            }),
            options: {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
                signal
            }
        });
        return autocompleteResponse;
    } catch (err) {
        return normalizedApiError(err);
    }
};

export const getAllUserProfilesForUser = async (
    {
        userId
    }: {
        userId: string;
    },
    signal?: AbortSignal
) => {
    try {
        const response = await normalizedFetch({
            endpoint: PROXY_ROUTES.fetcAllUserProfilesForUser.build({ userId }),
            options: {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
                signal
            }
        });
        return response;
    } catch (err) {
        return normalizedApiError(err);
    }
};

export const getUserProfileDisplayGraphByUserProfileId = async (
    {
        userProfileId
    }: {
        userProfileId: string;
    },
    signal?: AbortSignal
) => {
    try {
        const profileResponse = await normalizedFetch({
            endpoint: PROXY_ROUTES.fetchUserProfileDisplayGraphByUserProfileId.build({ userProfileId }),
            options: {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
                signal
            }
        });
        return profileResponse;
    } catch (err) {
        return normalizedApiError(err);
    }
};

export const getProfileDetailByUsername = async (
    {
        username
    }: {
        username: string;
    },
    signal?: AbortSignal
): Promise<ApiResponseOrError<UserProfileEntity>> => {
    try {
        const profileResponse = await normalizedFetch({
            endpoint: PROXY_ROUTES.fetchProfileDetailByProfileUsername.build({ username }),
            options: {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
                ...(signal ? { signal } : {})
            }
        });
        return profileResponse;
    } catch (err) {
        return normalizedApiError(err);
    }
};

export const getProfileListingsByPage = async (
    {
        queryParams,
        username
    }: {
        queryParams: ParsedQuery;
        username: string;
    },
    signal?: AbortSignal
) => {
    try {
        const listingResponse = await normalizedFetch({
            endpoint: PROXY_ROUTES.fetchProfileListingsByPage.build({
                queryParams,
                username
            }),
            options: {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
                ...(signal ? { signal } : {})
            }
        });

        return listingResponse;
    } catch (err) {
        return normalizedApiError(err);
    }
};
