import React, { Component } from 'react';
import { serializeError } from 'serialize-error';
import { reportUIError } from '../server/api-proxy/errors';
import { UIErrorPage } from './UIErrorPage';

interface ErrorBoundaryProps {
    readonly children: React.ReactNode;
    readonly renderFallback?: (error: Error) => React.ReactNode;
}

interface ErrorBoundaryState {
    error: Error | null;
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    public state: ErrorBoundaryState = {
        error: null
    };

    public componentDidCatch(caughtError: Error, errorInfo: React.ErrorInfo): void {
        const jsonError = serializeError(caughtError);

        console.log('caughtError ------>\n', caughtError);

        this.setState({
            error: caughtError
        });

        reportUIError({ jsonError });
    }

    public render() {
        const { children, renderFallback } = this.props;
        const { error } = this.state;

        if (!error) {
            return children;
        }

        return renderFallback ? renderFallback(error) : <UIErrorPage />;
    }

    public static defaultProps = {
        renderFallback: undefined
    };
}
