import React from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';
import { StyledTermsSection } from '../styled-shared';
import { LegalContentRestrictionsText } from './LegalContentRestrictionsText';

export const TermsOfServiceText = ({ titleVariant = 'h1' }: { readonly titleVariant?: 'h1' | 'h4' }) => {
    const { t } = useTranslation();

    return (
        <StyledTermsSection>
            <Typography
                variant="h1"
                sx={{
                    fontSize: {
                        xs: '1.5rem',
                        sm: '2rem',
                        md: '2.5rem'
                    }
                }}
            >
                {t('legal:terms.title')}
            </Typography>
            <Typography variant="caption" sx={{ display: 'block', mb: 3 }}>
                {t('legal:terms.last_updated')}
            </Typography>

            <Typography variant="body1" paragraph>
                {t('legal:terms.paragraph_1')}
            </Typography>
            <Typography variant="body1" paragraph>
                {t('legal:terms.paragraph_2')}
            </Typography>
            <Typography variant="body1" paragraph>
                {t('legal:terms.paragraph_3')}
            </Typography>

            <Typography variant="h5">{t('legal:terms.parties.title')}</Typography>
            <Typography variant="body1" paragraph>
                {t('legal:terms.parties.paragraph_1')}
            </Typography>
            <Typography variant="body1" paragraph>
                {t('legal:terms.parties.paragraph_2')}
            </Typography>
            <Typography variant="body1" paragraph>
                {t('legal:terms.parties.paragraph_3')}
            </Typography>

            <Typography variant="h5">{t('legal:terms.account_information.title')}</Typography>
            <Typography variant="body1" paragraph>
                {t('legal:terms.account_information.paragraph_1')}
            </Typography>

            <Typography variant="h5">{t('legal:terms.service_fees.title')}</Typography>
            <Typography variant="body1" paragraph>
                {t('legal:terms.service_fees.paragraph_1')}
            </Typography>

            <Typography variant="h5">{t('legal:terms.personal_information.title')}</Typography>
            <Typography variant="body1" paragraph>
                {t('legal:terms.personal_information.paragraph_1')}
            </Typography>

            <Typography variant="h5">{t('legal:terms.content.title')}</Typography>
            <Typography variant="body1" paragraph>
                {t('legal:terms.content.paragraph_1')}
            </Typography>

            <LegalContentRestrictionsText />

            <Typography variant="body1" paragraph>
                {t('legal:terms.content_control.paragraph_3')}
            </Typography>

            <Typography variant="h5">{t('legal:terms.disputes.title')}</Typography>
            <Typography variant="body1" paragraph>
                {t('legal:terms.disputes.paragraph_1')}
            </Typography>
            <Typography variant="body1" paragraph>
                {t('legal:terms.disputes.paragraph_2')}
            </Typography>

            <Typography variant="h5">{t('legal:terms.intellectual_property.title')}</Typography>
            <Typography variant="body1" paragraph>
                {t('legal:terms.intellectual_property.paragraph_1')}
            </Typography>
            <Typography variant="body1" paragraph>
                {t('legal:terms.intellectual_property.paragraph_2')}
            </Typography>
            <Typography variant="body1" paragraph>
                {t('legal:terms.intellectual_property.paragraph_3')}
            </Typography>
            <Typography variant="body1" paragraph>
                {t('legal:terms.intellectual_property.paragraph_4')}
            </Typography>
            <Typography variant="body1" paragraph>
                {t('legal:terms.intellectual_property.paragraph_5')}
                <a href="http://www.copyright.gov/legislation/pdfdmca.pdf">
                    http://www.copyright.gov/legislation/pdfdmca.pdf
                </a>
            </Typography>

            <Typography variant="h5">{t('legal:terms.breach.title')}</Typography>
            <Typography variant="body1" paragraph>
                {t('legal:terms.breach.paragraph_1')}
            </Typography>

            <Typography variant="h5">{t('legal:terms.compliance_and_indemnification.title')}</Typography>
            <Typography variant="body1" paragraph>
                {t('legal:terms.compliance_and_indemnification.paragraph_1')}
                <Typography variant="body1" component="span" sx={{ textDecoration: 'underline', ml: 1 }}>
                    {t('legal:terms.compliance_and_indemnification.paragraph_switchblade_emphasis')}
                </Typography>
            </Typography>
            <Typography variant="body1" paragraph>
                {t('legal:terms.compliance_and_indemnification.paragraph_2')}
            </Typography>
            <Typography variant="body1" paragraph>
                {t('legal:terms.compliance_and_indemnification.paragraph_3')}
            </Typography>
            <Typography variant="body1" paragraph>
                {t('legal:terms.compliance_and_indemnification.paragraph_4')}
            </Typography>
            <Typography variant="body1" paragraph>
                {t('legal:terms.compliance_and_indemnification.paragraph_5')}
            </Typography>
            <Typography variant="body1" paragraph>
                {t('legal:terms.compliance_and_indemnification.paragraph_6')}
            </Typography>
            <Typography variant="body1" paragraph>
                {t('legal:terms.compliance_and_indemnification.paragraph_7')}
            </Typography>
            <Typography variant="body1" paragraph>
                {t('legal:terms.compliance_and_indemnification.paragraph_8')}
            </Typography>
        </StyledTermsSection>
    );
};
