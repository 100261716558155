import { updateUserTermsAgreementRequest } from '../user-terms';

export const updateUserTermsAgreement = async ({ userId }: { userId: string }, signal?: AbortSignal) => {
    const response = await updateUserTermsAgreementRequest({ userId });

    const { error: { message: apiErrorMessage } = {} } = response as {
        error?: {
            message: string;
        };
    };

    if (apiErrorMessage) {
        throw new Error(apiErrorMessage);
    }

    return response;
};
