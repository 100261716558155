import React, { useMemo } from 'react';
import { default as NextImage } from 'next/image';
import Link from 'next/link';
import styled from '@emotion/styled';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import { getCdnUrl } from '@bladebinge/web-service-common/src/utils/get-cdn-url';
import { useTranslation } from 'react-i18next';
import { useShoppingCartContext } from '../../context/shopping-cart/shopping-cart-context';
import { useHasMounted } from '../../hooks/use-has-mounted';

const StyledCartAnchor = styled(Link)(({ theme }) => ({
    display: 'inline-block',
    position: 'relative',
    height: '35px',
    marginRight: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    '& .cart-icon': {
        opacity: 1,
        '&:hover': {
            opacity: 0
        }
    },
    '& .cart-icon-hover': {
        opacity: 0,
        position: 'absolute',
        top: 0,
        left: 0
    },
    '&:hover .cart-icon': {
        opacity: 0
    },
    '&:hover .cart-icon-hover': {
        opacity: 1
    }
}));

export const CartNavButton = () => {
    const { t } = useTranslation();
    const hasMounted = useHasMounted();
    const { cartItemsCount } = useShoppingCartContext();

    const iconHoverSrc = useMemo(
        () => getCdnUrl(`/logos/cart_${cartItemsCount > 0 && hasMounted ? 'full' : 'empty'}_hover_icon_v3.png`),
        [cartItemsCount, hasMounted]
    );
    const iconSrc = useMemo(
        () => getCdnUrl(`/logos/cart_${cartItemsCount > 0 && hasMounted ? 'full' : 'empty'}_icon_v3.png`),
        [cartItemsCount, hasMounted]
    );

    return (
        <StyledCartAnchor href="/cart" title={t('common:cart.your_cart')}>
            <Badge
                badgeContent={hasMounted ? cartItemsCount : 0}
                color="error"
                overlap="circular"
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                sx={{
                    display: 'block',
                    position: 'relative',
                    top: '-2px',
                    right: '-2px'
                }}
                component="div"
            >
                <Box
                    sx={{
                        width: '35px',
                        height: '40px',
                        display: 'block',
                        aspectRatio: 0.88
                    }}
                >
                    <NextImage className="cart-icon" src={iconSrc} alt="" width={35} height={40} priority />
                    <NextImage className="cart-icon-hover" src={iconHoverSrc} alt="" width={35} height={40} priority />
                </Box>
            </Badge>
        </StyledCartAnchor>
    );
};
