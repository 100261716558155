import { UserInterfaceNotificationMenuItemTypes } from '@bladebinge/types';

export const getNotificationMenuLinkHref = ({
    displayType,
    loggedInUserId
}: {
    displayType: UserInterfaceNotificationMenuItemTypes;
    loggedInUserId?: string | null;
}): string => {
    if (!loggedInUserId) {
        return '';
    }

    switch (displayType) {
        case 'SHIPMENT_AWAITING_SHIPMENT': {
            return `/users/${loggedInUserId}/account?view=sales&showNeedsLabel=true&showNeedsShipping=true`;
        }

        case 'SHIPMENT_SHIPPING_LABEL_REQUIRED': {
            return `/users/${loggedInUserId}/account?view=sales&showNeedsLabel=true&showNeedsShipping=true`;
        }

        case 'BUYER_FEEDBACK_REQUESTED': {
            return `/users/${loggedInUserId}/feedback?view=sale_feedback_needed`;
        }

        case 'SELLER_FEEDBACK_REQUESTED': {
            return `/users/${loggedInUserId}/feedback?view=purchase_feedback_needed`;
        }

        case 'NEW_MESSAGE': {
            return `/users/${loggedInUserId}/messaging?isRead=false&view=message_inbox`;
        }

        case 'FEEDBACK': {
            return `/users/${loggedInUserId}/feedback?view=received`;
        }

        case 'PRICE_DROP': {
            return `/users/${loggedInUserId}/price_drop_notifications`;
        }

        case 'OUTGOING_PAYMENT_ERROR': {
            return `/users/${loggedInUserId}/update_secure_payment_link`;
        }

        default: {
            return '';
        }
    }
};
