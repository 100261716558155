import React from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Popover from '@mui/material/Popover';
import { useHasMounted } from '../../../hooks/use-has-mounted';
import { useUserInterfaceNotificationsContext } from '../../../context/user-interface-notifications/user-interface-notifications-context';
import { StyledModalCancelIcon, StyledNarrowModalPaper } from '../../styled-shared';
import { NotificationMenuListItem } from './NotificationMenuListItem';

export const UserInterfaceNotificationsMenu = ({ anchorEl }: { readonly anchorEl: HTMLElement | null }) => {
    const hasMounted = useHasMounted();

    const { isMenuOpen, sortedCountsData, toggleMenu } = useUserInterfaceNotificationsContext();

    if (!isMenuOpen || anchorEl === null || !hasMounted) {
        return null;
    }

    return (
        <Popover
            anchorEl={anchorEl}
            elevation={3}
            id="customized-menu"
            keepMounted
            open={isMenuOpen}
            onClose={() => toggleMenu()}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            sx={{
                top: '40px',
                left: {
                    xs: 0,
                    sm: 0,
                    md: '40px'
                }
            }}
        >
            <StyledNarrowModalPaper>
                <StyledModalCancelIcon onClick={toggleMenu} />
                <Grid container sx={{ pt: 0.25 }}>
                    <Grid item xs={12}>
                        <List>
                            {sortedCountsData.map(([displayType, count]) => (
                                <NotificationMenuListItem key={displayType} displayType={displayType} count={count} />
                            ))}
                        </List>
                    </Grid>
                </Grid>
            </StyledNarrowModalPaper>
        </Popover>
    );
};
