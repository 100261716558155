import React, { useState } from 'react';
import styled from '@emotion/styled';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'next-i18next';
import { useSearchFilterContext } from '../../context/search-filter/search-filter-context';

const StyledFilterSearch = styled(Input)(({ theme }) => ({
    color: theme.palette.mode === 'dark' ? 'white' : theme.palette.primary.main,
    fontSize: '1.2em',
    padding: '0 6px'
}));

export const FilterSearch = ({ className = '' }: { readonly className?: string }) => {
    const { t } = useTranslation();
    const { setActiveFilterDrawerSearch } = useSearchFilterContext();
    const [currentSearch, setCurrentSearch] = useState<string>('');

    const setLowerSearch = (search: string = '') => {
        setCurrentSearch(search.toLowerCase());
    };

    const handleFilterSearch = (searchVal: string = '') => {
        // to ensure that the value passed to api matches input
        // debounce can cause characters to be left off
        setLowerSearch(searchVal);
        setActiveFilterDrawerSearch(searchVal);
    };

    const handleClear = () => {
        setCurrentSearch('');
        handleFilterSearch('');
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        setLowerSearch(e.target.value);
        handleFilterSearch(e.target.value);
    };

    const handleInputKeyUp = (e: React.KeyboardEvent) => {
        e.stopPropagation();
        if (e.key === 'Enter') {
            handleFilterSearch(currentSearch);
        }

        if (e.key === 'Escape') {
            handleClear();
        }
    };

    return (
        <StyledFilterSearch
            className={className}
            id="input-with-icon-adornment"
            aria-label={t('common:filters.util.search_filters')}
            placeholder={t('common:filters.util.search_filters')}
            fullWidth
            onChange={handleInputChange}
            onKeyUp={handleInputKeyUp}
            endAdornment={
                currentSearch ? (
                    <InputAdornment position="end">
                        <CancelOutlinedIcon
                            aria-label={t('navigation.clear_search')}
                            onClick={handleClear}
                            sx={{ cursor: 'pointer', mr: 2.5 }}
                        />
                    </InputAdornment>
                ) : null
            }
            startAdornment={
                <InputAdornment position="start">
                    <SearchIcon onClick={() => handleFilterSearch(currentSearch)} />
                </InputAdornment>
            }
            value={currentSearch}
            sx={{ mt: 1, px: 1 }}
        />
    );
};
