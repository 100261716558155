import React from 'react';
import { useTranslation } from 'next-i18next';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Grid from '@mui/material/Grid';
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { noop } from '../../utils/noop';
import { useSearchFilterContext } from '../../context/search-filter/search-filter-context';

export const FilterTreeButtons = () => {
    const { t } = useTranslation();
    const {
        clearFilters,
        filterMode,
        forceAnyMode,
        isFilterContextDirty,
        hasFilterContextSelections,
        performSearch,
        // resetFilters,
        setIsFilterDrawerOpen,
        setFilterMode
    } = useSearchFilterContext();

    const [popoverAnchorEl, setPopoverAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const applyButton = isFilterContextDirty ? (
        <Button
            color="primary"
            id="filter_drawer_apply_btn"
            variant="contained"
            onClick={(e) => {
                e.stopPropagation();
                performSearch();
                setIsFilterDrawerOpen(false);
            }}
        >
            {t('common:general.apply')}
        </Button>
    ) : (
        <Button disabled color="secondary" onClick={noop}>
            {t('common:general.apply')}
        </Button>
    );

    const clearButton = hasFilterContextSelections ? (
        <Button color="secondary" id="filter_drawer_clear_btn" onClick={clearFilters} variant="contained">
            {t('common:general.clear')}
        </Button>
    ) : (
        <Button disabled color="secondary" onClick={noop}>
            {t('common:general.clear')}
        </Button>
    );

    const handleFilterModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isMatchAll = event.target.checked;
        setFilterMode(isMatchAll ? 'ALL' : 'ANY');
    };

    const handlePopoverTriggerClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setPopoverAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setPopoverAnchorEl(null);
    };

    const open = Boolean(popoverAnchorEl);
    const id = open ? 'force-filter-mode-any-popover' : undefined;

    return (
        <Grid container justifyContent="space-between">
            <Grid item xs={6}>
                <Grid component="label" container alignItems="center" justifyContent="center">
                    <Grid item sx={{ position: 'relative' }}>
                        {forceAnyMode && (
                            <>
                                <IconButton
                                    onClick={handlePopoverTriggerClick}
                                    sx={{ position: 'absolute', left: '-40px', top: '-5px' }}
                                >
                                    <HelpIcon sx={{ color: 'text.secondary', fontSize: '1.4rem' }} />
                                </IconButton>
                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={popoverAnchorEl}
                                    onClose={handlePopoverClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center'
                                    }}
                                    elevation={3}
                                    PaperProps={{
                                        sx: {
                                            padding: '8px',
                                            backgroundColor: 'rgba(0,0,0, 0.95)',
                                            color: 'white'
                                        }
                                    }}
                                >
                                    <Typography variant="body1" sx={{ left: '10px' }}>
                                        {t('common:general.filter_drawer_force_any_mode_tooltip')}
                                    </Typography>
                                </Popover>
                            </>
                        )}
                        <Typography variant="caption">{t('common:general.match_any')}</Typography>
                    </Grid>
                    <Grid item>
                        <Switch
                            disabled={forceAnyMode}
                            checked={filterMode === 'ALL'}
                            onChange={handleFilterModeChange}
                            name="filter_mode_selector"
                            color="primary"
                        />
                    </Grid>
                    <Grid item>
                        <Typography variant="caption">{t('common:general.match_all')}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <ButtonGroup
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        margin: '.25rem 0'
                    }}
                    size="small"
                    aria-label="small button group"
                >
                    {clearButton}
                    {/* {resetButton} */}
                    {applyButton}
                </ButtonGroup>
            </Grid>
        </Grid>
    );
};
