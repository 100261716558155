import { useQuery } from '@tanstack/react-query';
import { uiCacheKeyBuilderMap } from '@bladebinge/web-service-common/src/utils/cache-key-builder';
import { fetchUserAccount } from '../../server/api-proxy/account';

const userAccountFetchQueryFn = (userId: string) => async () => {
    const response = await fetchUserAccount(userId as string);
    const { error: { message: asyncError = '' } = {} } = response as { error?: { message: string } };

    if (asyncError) {
        // we expect a 404 if the user does not have a referrer. Just return false on error
        return false;
    }

    return response;
};

export const useAccountGraphQuery = (userId?: string | null) =>
    useQuery({
        enabled: Boolean(userId),
        queryKey: uiCacheKeyBuilderMap.loggedInUserAccount({ loggedInUserId: userId }),
        queryFn: userAccountFetchQueryFn(userId as string)
    });
