import type { ApiResponseOrError } from '@bladebinge/types';
import type { ErrorObject } from 'serialize-error';
import { PROXY_ROUTES } from './ui-proxy-routes';

export const reportUIError = async (
    { jsonError }: { jsonError: ErrorObject },
    signal?: AbortSignal
): Promise<ApiResponseOrError<string>> => {
    try {
        const uiServerBaseUrl = process?.env?.ENV_UI_SERVER_URL
            ? process.env.ENV_UI_SERVER_URL
            : 'http://localhost:1558';

        const errorReportingUrl = new URL(PROXY_ROUTES.uiError.build(), uiServerBaseUrl).toString();

        const errorReportResponse = await fetch(errorReportingUrl, {
            method: 'POST',
            body: JSON.stringify({
                error: jsonError
            }),
            ...(signal ? { signal } : {}),
            headers: {
                'Content-Type': 'application/json'
            }
        });

        const jsonResponse = await errorReportResponse.json();

        return jsonResponse;
    } catch (err) {
        return '';
    }
};
