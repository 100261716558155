import { PROXY_ROUTES } from './ui-proxy-routes';
import { normalizedApiError } from './utils/normalized-api-error';
import { normalizedFetch } from './utils/normalized-fetch';

export const updateUserTermsAgreementRequest = async (
    {
        userId
    }: {
        userId: string;
    },
    signal?: AbortSignal
) => {
    try {
        const response = await normalizedFetch({
            endpoint: PROXY_ROUTES.updateUserTermsRequest.build({ userId }),
            options: {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
                signal
            }
        });
        return response;
    } catch (err) {
        return normalizedApiError(err);
    }
};
