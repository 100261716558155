import React from 'react';
import { useTranslation } from 'next-i18next';
import styled from '@emotion/styled';
import ListItem from '@mui/material/ListItem';
import { UserInterfaceNotificationMenuItemTypes } from '@bladebinge/types';
import Grid from '@mui/material/Grid';
import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';
import { useMe } from '../../../context/me/me-context';
import { getNotificationMenuLinkHref } from './utils/get-notification-menu-link-href';
import { NotificationMenuDisplayTypeIcon } from './NotificationMenuDisplayTypeIcon';

export const StyledNotifcationLinkWrap = styled('a')(({ theme }) => ({
    display: 'block',
    width: '100%',
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
        backgroundColor: 'rgba(224, 224, 224, 0.15)'
    }
}));

export const NotificationMenuListItem = ({
    count,
    displayType
}: {
    readonly displayType: UserInterfaceNotificationMenuItemTypes;
    readonly count: number;
}) => {
    const { t } = useTranslation();
    const { id: loggedInUserId } = useMe();

    if (count === 0) {
        return null;
    }

    const menuLink = getNotificationMenuLinkHref({ displayType, loggedInUserId });

    const itemContent = (
        <Grid container alignContent="space-between" alignItems="center" spacing={1}>
            <Grid item xs={1}>
                <NotificationMenuDisplayTypeIcon displayType={displayType} sx={{ fontSize: '20px', mt: 1, ml: 0.5 }} />
            </Grid>
            <Grid item xs={10}>
                <Typography variant="body1">{t(`common:notifications_menu.display_types.${displayType}`)}</Typography>
            </Grid>
            <Grid item xs={1}>
                <Badge badgeContent={count} color="primary" max={99} overlap="rectangular" variant="standard" />
            </Grid>
        </Grid>
    );

    return (
        <ListItem
            sx={{
                cursor: 'pointer',
                px: 0,
                borderBottomStyle: 'solid',
                borderBottomWidth: '1px',
                borderBottomColor: 'divider',
                '&:last-child': {
                    borderBottomColor: 'transparent'
                }
            }}
        >
            {menuLink ? (
                <StyledNotifcationLinkWrap href={menuLink}>{itemContent}</StyledNotifcationLinkWrap>
            ) : (
                itemContent
            )}
        </ListItem>
    );
};
